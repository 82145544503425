.team-chat-container {
  display: flex;
  height: 82vh;
  border: 1px solid var(--border-color);
  border-radius: 4px;
}

.chat-sidebar {
  width: 300px;
  padding: 10px;
  border-right: 1px solid var(--border-color);
}

.recent-chats-section {
  overflow-y: auto;
  height: 50vh;
  padding-right: 8px;
}

.main-chat {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.chat-title {
  font-size: 24px;
  font-weight: bold;
}

.chat-members {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.chat-members li {
  margin-bottom: 10px;
}

.chat-members li:last-child {
  margin-bottom: 0;
}

.chat-members li a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--text-color);
}

.chat-members li a:hover {
  text-decoration: underline;
}

.chat-members li a .avatar {
  margin-right: 10px;
}

.chat-members li a .status {
  margin-left: auto;
}

.chat-members li a .status.online {
  color: green;
}

.chat-members li a .status.offline {
  color: red;
}

.chat-members li a .status.busy {
  color: orange;
}

.chat-members li a .status.away {
  color: gray;
}

.chat-members li a .status.dnd {
  color: red;
}

.chat-members li a .status.idle {
  color: gray;
}

.profile-info h2,
.recent-chats h3 {
  font-size: 14px;
  margin: 0;
  font-weight: 600;
  color: var(--text-color);
  letter-spacing: -0.1px;
  font-family: "DM Sans";
}

.recent-chats h3 {
  text-decoration: solid var(--maroon-color) underline;
  margin: 10px 0;
  font-size: 16px;
  font-weight: 600;
}

.user-profile,
.chat-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
}

.chat-header {
  border-bottom: 1px solid var(--border-color);
  padding: 10px;
  margin-bottom: 0;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.status {
  color: var(--success-color);
  font-size: 12px;
  margin-bottom: 0;
}

.ellipse-btn {
  background: var(--white-color);
  border: 0;
}

.search-bar {
  position: relative;
}

.search-bar svg,
.chat-actions svg {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 14px;
  color: var(--maroon-color);
}

.search-bar input,
.message-input input {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 20px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.1px;
  color: var(--text-color);
  font-family: "DM Sans";
}

.search-bar input:focus,
.message-input input:focus {
  outline: none;
}

.chat-filters {
  display: flex;
  gap: 4px;
  margin: 10px 0;
}

.chat-filters button {
  padding: 5px 10px;
  border: none;
  border-radius: 20px;
  background-color: var(--white-color);
  color: var(--text-color);
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: 1px solid var(--border-color);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.1px;
  font-family: "DM Sans";
}

.chat-filters button.active {
  background-color: var(--maroon-color);
  color: var(--white-color);
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  flex-direction: column;
  display: flex;
}

.message {
  background-color: var(--nav-color);
  padding: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 24px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  margin-bottom: 10px;
  width: fit-content;
  word-wrap: break-word;
}

.no-chat-selected {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 68vh;
}

.message.received {
  align-self: flex-start;
}

.message .time {
  font-size: 10px;
  font-weight: 500;
  color: var(--text-semi-color);
}

.date-separator {
  display: flex;
  align-items: center;
}

.date-separator button {
  text-align: center;
  color: var(--maroon-color);
  border: 1px solid var(--border-color);
  border-radius: 24px;
  background: var(--white-color);
  font-size: 12px;
  font-weight: 500;
  padding: 6px 12px;
  margin: 10px 8px;
}

.line-horizontal {
  width: 100%;
  background: var(--border-color);
  height: 1px;
}

.message-input {
  display: flex;
  padding: 10px;
  border-top: 1px solid var(--border-color);
  gap: 4px;
}

.message-input input {
  flex: 1;
  margin-right: 10px;
}

.message-input button {
  padding: 6px 12px;
  background-color: var(--maroon-color);
  color: var(--white-color);
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.chat-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  cursor: pointer;
  background-color: var(--background-color);
  margin: 4px 0;
}

.chat-item:hover {
  background-color: var(--border-color);
}

.active-chat {
  background-color: var(--nav-color) !important;
}

.chat-info {
  flex: 1;
  margin-left: 10px;
}

.chat-info h4 {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.1px;
  color: var(--text-color);
  margin-bottom: 0;
}

.chat-info p,
.chat-meta p {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.1px;
  color: var(--text-semi-color);
  margin-bottom: 0;
}

.chat-meta {
  text-align: right;
}

.unread-count {
  background-color: var(--maroon-color);
  color: var(--white-color);
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
}

.message p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.1px;
}

.message.self {
  background-color: var(--maroon-color);
  color: var(--white-color);
  border-top-left-radius: 24px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  text-align: end;
  align-self: flex-end;
}

.message.self .time {
  color: var(--white-color);
}

.chat-actions {
  display: flex;
  gap: 10px;
}

.team-member-card {
  border: 1px solid var(--border-color);
  padding: 20px;
  border-radius: 12px;
}

.team-member-view-btn {
  background-color: var(--maroon-color);
  color: var(--white-color);
  border: none;
  padding: 4px 32px;
  width: 100%;
  border-radius: 12px;
}

.team-member-card-details {
  flex: 1;
}

.team-member-card-details p {
  color: var(--text-semi-color);
  font-size: 16px;
  text-align: justify;
  min-width: 120px;
  /* Ensure enough space for labels */
  white-space: nowrap;
}

.team-member-card-details span {
  color: var(--black-color);
  font-size: 16px;
  font-weight: 700;
  text-align: left;
}

.team-details-card {
  border: 1px solid var(--border-color);
  padding: 24px 16px;
  border-radius: 12px;
}

.team-details-action-button {
  background-color: var(--nav-color);
  border: none;
  color: var(--maroon-color);
  border-radius: 5px;
  padding: 8px 16px;
}