.admin-dashboard-btn {
    background: var(--maroon-color);
    color: var(--white-color);
    border: 0;
    border-radius: 24px;
    padding: 8px 24px
}

.admin-dashboard-card {
    background-color: var(--background-color);
    border-radius: 12px;
    padding: 12px;
    border: 1px solid var(--border-color);
    font-family: "DM Sans";
}

.card-subtitle {
    color: var(--text-semi-color);
}

.card-title {
    font-weight: 800;
}

.admin-dashboard-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.admin-dashboard-title h2 {
    color: var(--text-color);
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.1px;
    margin-bottom: 0;
}

.admin-dashboard-total-overview {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.admin-pie-content {
    width: 35%;
}

.admin-progress-content {
    width: 65%;
}

.dashboard-latest-activity{
    background: var(--white-color);
    padding: 10px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    margin-bottom: 12px
}

.dashboard-smalley{
    font-size: 12px;
    font-weight: 500;
    color: var(--text-color);
}

.dashboard-hours{
    font-size: 10px;
    font-weight: 400;
    color: var(--text-semi-color);
}


.applicants-status {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .applicants-status h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }
  
 
  .applicant-status-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(175px, 1fr));
    gap: 16px;
  }
  
  .applicant-status-card {
    padding: 16px;
    background-color: var(--white-color);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .applicant-status-icon {
    font-size: 24px;
    padding: 10px;
    border-radius: 15px;
    color: var(--white-color);
    height: 45px;
    width: 45px;
    display: flex;
    justify-content: center;
  }
  
  .applicant-status-info h4 {
    font-size: 18px;
    margin: 0;
    color: var(--text-color);
    text-align: start;
  }
  
  .applicant-status-info p {
    font-size: 22px;
    font-weight: bold;
    text-align: start;
  }
  
  .applicant-status-change {
    font-size: 0.85em;
    display: block;
    margin-top: 4px;
  }
  
  .applicant-status-change.green {
    color: #28a745;
  }
  
  .applicant-status-change.red {
    color: var(--maroon-color);
  }
  .dashboard-toggle-button-group {
    display: flex;
    background-color: var(--nav-color);
    border-radius: 8px;
    padding: 4px;
  }
  
  .dashboard-toggle-button {
    flex: 1;
    background: none;
    border: none;
    color: var(--text-semi-color);
    font-size: 16px;
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 8px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .dashboard-toggle-button:hover {
    color: var(--text-color);
  }
  
  .dashboard-toggle-button.active {
    background-color: var(--maroon-color); 
    color: white;
  }
  .latest-activity-card-body{
    background-color: var(--white-color);
    padding: 25px;
    border-radius: 20px;
    height: 250px;
    justify-content: center;
    align-items: center;
}
  .latest-activity-card-body h4{
font-size: 16px;
  }
  .latest-activity-card-body h5{
    font-size: 18px;
      }
  .latest-activity-card-body img{
    width: 100px;
    height: 100px;
  }