.add-client-section h2 {
    font-size: 24px;
    font-weight: 700;
}

.client-upload-drag-icon {
    background-color: var(--nav-color);
    border-radius: 50%;
    padding: 10px;
    color: var(--maroon-color);
    font-size: 24px;
    border: none;
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
}

.client-upload-dragger.ant-upload-wrapper .ant-upload-drag {
    background: var(--white-color);
}
.ant-table-wrapper .ant-table-tbody >tr >td{
    background-color: var(--white-color);
}