.login-container {
    height: 100vh;
    display: flex;
    align-items: center;
    background: var(--white-color);
}

.login-left-panel-card {
    border-radius: 32px;
    position: relative;
    text-align: center;
}

.login-left-panel-card h2 {
    font-size: 24px;
    color: var(--text-color);
    margin-bottom: 10px;
}

.login-left-panel-card p {
    font-size: 16px;
    color: var(--danger-color);
    margin-bottom: 20px;
}

.login-left-panel-card img {
    max-width: 80%;
    border-radius: 32px;
}

.logo img {
    width: 160px;
    margin-bottom: 40px;
}

.login-right-panel h2 {
    font-size: 32px;
    font-weight: 900;
    letter-spacing: -0.2px;
    color: var(--black-color);
    margin-bottom: 10px;
}

.login-right-panel>p {
    font-size: 16px;
    color: var(--border-color);
    font-weight: 500;
    letter-spacing: -0.1px;
    margin-bottom: 30px;
}

.login-right-panel .master-all-login {
    background: var(--maroon-color);
    color: var(--white-color);
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.1px;
    border: 0;
    border-radius: 8px;
    padding: 10px 20px;
    margin-bottom: 20px;
}

.master-login-section button {
    background: var(--master-color);
    padding: 20px;
    border-radius: 8px;
    border: 0;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.1px;
    color: var(--black-color);
    margin-top: 20px;
    box-shadow: 0 4px 10px #00000003;
    text-align: left;
}

.master-login-section button:hover {
    background: var(--background-color);
    color: var(--maroon-color);
}

.master-icons {
    font-size: 48px;
    color: var(--maroon-color);
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: var(--text-color);
    margin-bottom: 4px;
}

.form-group input {
    width: 100%;
    padding: 10px;
    border: 0;
    border-radius: 8px;
    font-size: 14px;
    background: var(--input-color);
    box-shadow: 0 4px 10px #00000003;
}

.form-group input:focus {
    outline: none;
}

.form-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.remember-me {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: var(--text-color);
}

.remember-me input {
    margin-right: 8px;
}

.forget-password {
    font-size: 14px;
    color: var(--maroon-color);
    text-decoration: none;
}

.login-button {
    width: 100%;
    padding: 10px;
    background-color: var(--maroon-color);
    color: var(--white-color);
    border: none;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;
}

.login-pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.login-pagination span {
    width: 48px;
    height: 8px;
    background-color: var(--border-color);
    border-radius: 4px;
    margin: 0 5px;
}

.login-pagination span.active {
    background-color: var(--maroon-color);
}

.password-input-wrapper {
    position: relative;
}

.password-toggle-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.toggle-container {
    display: flex;
    align-items: center;
}

.toggle-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;
}

.toggle-container p {
    margin-left: 4px;
    font-size: 14px;
    color: var(--black-color);
    font-weight: 500;
    letter-spacing: -0.1px;
    margin-bottom: 0;
}

.toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--border-color);
    transition: 0.4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 19px;
    width: 19px;
    left: 3px;
    bottom: 3px;
    background-color: var(--white-color);
    transition: 0.4s;
    border-radius: 50%;
}

input:checked+.slider {
    background-color: var(--maroon-color);
}

input:checked+.slider:before {
    transform: translateX(24px);
}

.otp-input-group {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.otp-input {
    width: 50px;
    height: 50px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    border: 2px solid var(--border-color);
    border-radius: 10px;
    margin: 0 8px;
    color: var(--black-color);
    background-color: transparent;
    caret-color: transparent;
}

.otp-input:focus {
    outline: none;
    border-color: var(--maroon-color);
    caret-color: var(--white-color);
}

.resend-info {
    text-align: center;
}

.resend-info p {
    color: var(--text-color);
    font-size: 14px;
    font-weight: 600;
}

.resend-info span {
    color: var(--border-color);
    font-size: 14px;
    font-weight: 600;
}

.resend-link {
    color: var(--maroon-color) !important;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
}

.spinner {
    border: 2px solid rgba(0, 0, 0, 0.01);
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border-left-color: var(--white-color);
    animation: spin 1s linear infinite;
    margin: auto;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}






@media (max-width: 768px) {
    .login-container {
        height: 100%;
        max-height: 100%;
    }

    .login-left-panel,
    .login-right-panel {
        padding: 40px 0;
    }
}