.addpage-modal-title>h2 {
    font-size: 18px;
    font-weight: 700;
}

.addpage-modal-title>p {
    font-size: 10px;
    font-weight: lighter;
    color: #ACACB3;
}

.add-page-section {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.comments-layout {
    height: 198px;
    border-radius: 5px;
    border: 1px solid rgb(204, 204, 204);
}

.custom-dropdown {
    margin-top: 20px;
}