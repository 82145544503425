.ql-editor {
    height: 157px !important;
}

.add-recruiter-section h2 {
    font-size: 28px;
    font-weight: 800;
    letter-spacing: -0.1px;
    color: var(--black-color);
}

.ant-form-item .ant-form-item-label>label,
.ant-input,
.ant-radio-wrapper,
.ant-select-single .ant-select-selector,
.ant-upload-wrapper,
.form-label {
    color: var(--black-color);
}

.ant-input-outlined,
.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector,
.ant-input-outlined:hover,
.ant-input-outlined:focus,
.ant-picker-outlined,
.ant-input-outlined:focus-within,
.ant-picker-outlined:hover {
    background: var(--white-color) !important;
}

.form-control {
    color: var(--black-color) !important;
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled) {
    background: transparent;
}

.ql-snow .ql-stroke,
.ql-snow * {
    color: var(--black-color) !important;
}

.cancel-btn {
    border: 1px solid var(--maroon-color);
    font-size: 14px;
    font-weight: 500;
    background: var(--white-color);
    color: var(--maroon-color);
    margin-right: 8px;
    padding: 8px 16px;
    border-radius: 8px;
    font-family: "DM Sans";
}

.create-btn {
    border: 1px solid var(--maroon-color);
    font-size: 14px;
    font-weight: 500;
    background: var(--maroon-color);
    color: var(--white-color);
    margin-right: 8px;
    padding: 8px 16px;
    border-radius: 8px;
    font-family: "DM Sans";
}

.create-btn:hover {
    background: var(--white-color);
    color: var(--maroon-color);
}


.react-international-phone-input-container .react-international-phone-input,
.react-international-phone-country-selector-button {
    background-color: var(--white-color) !important;
    color: var(--black-color) !important;
}


.react-international-phone-input-container .react-international-phone-input,
.react-international-phone-input-container .react-international-phone-country-selector-button {
    height: 2rem !important;
}

.form-control {
    height: 2rem;
    align-items: center;
    display: flex;
}

.all-recruiter-section h2 {
    font-size: 24px;
    font-weight: 800;
    letter-spacing: -0.1px;
    color: var(--black-color);
}

.search-table-container {
    display: flex;
    align-items: center;
}

.search-table-container button {
    border: 1px solid var(--maroon-color);
    font-size: 14px;
    font-weight: 500;
    background: var(--maroon-color);
    color: var(--white-color);
    padding: 8px 16px;
    border-radius: 8px;
    font-family: "DM Sans";
}

.search-input-table {
    border: 1px solid var(--maroon-color) !important;
    border-radius: 20px !important;
    color: var(--text-semi-color) !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    padding: 6px 12px !important;
    width: 250px;
}

.all-recruiter-card {
    border: 1px solid var(--border-color);
    border-radius: 12px;
    padding: 0;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.01) 0px 4px 12px;
    margin-bottom: 24px;
}

.all-recruiter-image img {
    width: 200px;
    height: 200px;
    border-radius: 50%;

}

.all-recruiter-image {
    background-color: var(--background-color);
    justify-content: center;
    display: flex;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    padding: 10px 0;
}

.all-recruiter-card-description {
    padding: 18px 16px;
}

.all-recruiter-card-description h4 {
    font-size: 18px;
    font-weight: 600;
}

.all-recruiter-card-description h5 {
    font-size: 14px;
    color: var(--text-semi-color);
    font-weight: 500;

}

.all-recruiter-card-description p {
    font-size: 15px;
    margin-bottom: 4px;
}

.profile-btn {
    border: 1px solid var(--maroon-color);
    font-size: 16px;
    font-weight: 500;
    background: var(--white-color);
    color: var(--maroon-color);
    margin-right: 8px;
    padding: 6px 32px;
    border-radius: 8px;
    font-family: "DM Sans";
    width: 150px;
}

.contact-btn {
    border: 1px solid var(--maroon-color);
    font-size: 16px;
    font-weight: 500;
    background: var(--maroon-color);
    color: var(--white-color);
    margin-right: 8px;
    padding: 6px 32px;
    border-radius: 8px;
    font-family: "DM Sans";
    width: 150px;
}

.pagination-div h5 {
    font-size: 16px;
    color: var(--text-semi-color);
}

.ant-pagination .ant-pagination-item-active {
    border-color: var(--maroon-color);
}

.icon-red {
    color: var(--maroon-color);
    font-size: 14px;
    font-weight: 600;
}

.recruiter-profile-section h2 {
    font-size: 22px;
    font-weight: 600;
    letter-spacing: -0.1px;
    color: var(--black-color);
}

.recruiter-profile-row {
    border: 1px solid var(--border-color);
    border-radius: 12px;
    padding: 32px 24px;
}

.recruiter-profile-image {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    background-color: var(--background-color);
}

.recruiter-profile-description h4 {
    font-size: 18px;
    font-weight: 600;
}

.recruiter-profile-description p {
    font-size: 15px;
    margin-bottom: 4px;
    color: var(--text-semi-color);

}

.recruiter-personal-information {
    border: 1px solid var(--border-color);
    border-radius: 12px;
    padding: 32px 24px;
}

.recruiter-personal-information h4 {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.1px;
    color: var(--text-color);
}

.recruiter-personal-information h5 {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.1px;
    margin-bottom: 20px;

}

.recruiter-personal-information p {
    color: var(--text-semi-color);
    font-size: 16px;
    margin-bottom: 4px;
}

.recruiter-text-underline {
    border: 0.5px solid var(--border-color)
}

.right-arrow {
    float: right;
    margin-top: 4px;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover {
    background-color: var(--maroon-color);

}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover {
    color: var(--white-color)
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
    margin: 5px;
}

@media (max-width: 768px) {
    .all-recruiter-card {
        width: 100%;
        margin-bottom: 20px;
    }

}
.ant-select .ant-select-selection-placeholder{
    color: var(--text-semi-color);
}