.recruiter-lead-team-chat-container {
    display: flex;
    height: 82vh;
    border: 1px solid var(--border-color);
    border-radius: 4px;
  }
  
  .recruiter-lead-chat-sidebar {
    width: 300px;
    padding: 10px;
    border-right: 1px solid var(--border-color);
  }
  
  .recruiter-lead-recent-chats-section {
    overflow-y: auto;
    height: 50vh;
    padding-right: 8px;
  }
  
  .recruiter-lead-main-chat {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .recruiter-lead-chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .recruiter-lead-chat-title {
    font-size: 24px;
    font-weight: bold;
  }
  
  .recruiter-lead-chat-members {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .recruiter-lead-chat-members li {
    margin-bottom: 10px;
  }
  
  .recruiter-lead-chat-members li:last-child {
    margin-bottom: 0;
  }
  
  .recruiter-lead-chat-members li a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--text-color);
  }
  
  .recruiter-lead-chat-members li a:hover {
    text-decoration: underline;
  }
  
  .recruiter-lead-chat-members li a .recruiter-lead-avatar {
    margin-right: 10px;
  }
  
  .recruiter-lead-chat-members li a .recruiter-lead-status {
    margin-left: auto;
  }
  
  .recruiter-lead-chat-members li a .recruiter-lead-status.online {
    color: green;
  }
  
  .recruiter-lead-chat-members li a .recruiter-lead-status.offline {
    color: red;
  }
  
  .recruiter-lead-chat-members li a .recruiter-lead-status.busy {
    color: orange;
  }
  
  .recruiter-lead-chat-members li a .recruiter-lead-status.away {
    color: gray;
  }
  
  .recruiter-lead-chat-members li a .recruiter-lead-status.dnd {
    color: red;
  }
  
  .recruiter-lead-chat-members li a .recruiter-lead-status.idle {
    color: gray;
  }
  
  .recruiter-lead-profile-info h2,
  .recruiter-lead-recent-chats h3 {
    font-size: 14px;
    margin: 0;
    font-weight: 600;
    color: var(--text-color);
    letter-spacing: -0.1px;
    font-family: "DM Sans";
  }
  
  .recruiter-lead-recent-chats h3 {
    text-decoration: solid var(--maroon-color) underline;
    margin: 10px 0;
    font-size: 16px;
    font-weight: 600;
  }
  
  .recruiter-lead-user-profile,
  .recruiter-lead-chat-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: space-between;
  }
  
  .recruiter-lead-chat-header {
    border-bottom: 1px solid var(--border-color);
    padding: 10px;
    margin-bottom: 0;
  }
  
  .recruiter-lead-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .recruiter-lead-status {
    color: var(--success-color);
    font-size: 12px;
    margin-bottom: 0;
  }
  
  .recruiter-lead-ellipse-btn {
    background: var(--white-color);
    border: 0;
  }
  
  .recruiter-lead-search-bar {
    position: relative;
  }
  
  .recruiter-lead-search-bar svg,
  .recruiter-lead-chat-actions svg {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 14px;
    color: var(--maroon-color);
  }
  
  .recruiter-lead-search-bar input,
  .recruiter-lead-message-input input {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 20px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.1px;
    color: var(--text-color);
    font-family: "DM Sans";
  }
  
  .recruiter-lead-search-bar input:focus,
  .recruiter-lead-message-input input:focus {
    outline: none;
  }
  
  .recruiter-lead-chat-filters {
    display: flex;
    gap: 4px;
    margin: 10px 0;
  }
  
  .recruiter-lead-chat-filters button {
    padding: 5px 10px;
    border: none;
    border-radius: 20px;
    background-color: var(--white-color);
    color: var(--text-color);
    cursor: pointer;
    transition: background-color 0.3s ease;
    border: 1px solid var(--border-color);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: -0.1px;
    font-family: "DM Sans";
  }
  
  .recruiter-lead-chat-filters button.active {
    background-color: var(--maroon-color);
    color: var(--white-color);
  }
  
  .recruiter-lead-chat-messages {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
    flex-direction: column;
    display: flex;
  }
  
  .recruiter-lead-message {
    background-color: var(--nav-color);
    padding: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 24px;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    margin-bottom: 10px;
    width: fit-content;
    word-wrap: break-word;
  }
  
  .recruiter-lead-no-chat-selected {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 68vh;
  }
  
  .recruiter-lead-message.received {
    align-self: flex-start;
  }
  
  .recruiter-lead-message .time {
    font-size: 10px;
    font-weight: 500;
    color: var(--text-semi-color);
  }
  
  .recruiter-lead-date-separator {
    display: flex;
    align-items: center;
  }
  
  .recruiter-lead-date-separator button {
    text-align: center;
    color: var(--maroon-color);
    border: 1px solid var(--border-color);
    border-radius: 24px;
    background: var(--white-color);
    font-size: 12px;
    font-weight: 500;
    padding: 6px 12px;
    margin: 10px 8px;
  }
  
  .recruiter-lead-line-horizontal {
    width: 100%;
    background: var(--border-color);
    height: 1px;
  }
  
  .recruiter-lead-message-input {
    display: flex;
    padding: 10px;
    border-top: 1px solid var(--border-color);
    gap: 4px;
  }
  
  .recruiter-lead-message-input input {
    flex: 1;
    margin-right: 10px;
  }
  
  .recruiter-lead-message-input button {
    padding: 6px 12px;
    background-color: var(--maroon-color);
    color: var(--white-color);
    border: none;
    border-radius: 20px;
    cursor: pointer;
  }
  
  .recruiter-lead-chat-item {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    cursor: pointer;
    background-color: var(--background-color);
    margin: 4px 0;
  }
  
  .recruiter-lead-chat-item:hover {
    background-color: var(--border-color);
  }
  
  .recruiter-lead-active-chat {
    background-color: var(--nav-color) !important;
  }
  
  .recruiter-lead-chat-info {
    flex: 1;
    margin-left: 10px;
  }
  
  .recruiter-lead-chat-info h4 {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.1px;
    color: var(--text-color);
    margin-bottom: 0;
  }
  
  .recruiter-lead-chat-info p,
  .recruiter-lead-chat-meta p {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.1px;
    color: var(--text-semi-color);
    margin-bottom: 0;
  }
  
  .recruiter-lead-chat-meta {
    text-align: right;
  }
  
  .recruiter-lead-unread-count {
    background-color: var(--maroon-color);
    color: var(--white-color);
    border-radius: 50%;
    padding: 2px 6px;
    font-size: 12px;
  }
  
  .recruiter-lead-message p {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.1px;
  }
  
  .recruiter-lead-message.self {
    background-color: var(--maroon-color);
    color: var(--white-color);
    border-top-left-radius: 24px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    text-align: end;
    align-self: flex-end;
  }
  
  .recruiter-lead-message.self .time {
    color: var(--white-color);
  }
  
  .recruiter-lead-chat-actions {
    display: flex;
    gap: 10px;
  }
  
  .recruiter-lead-team-member-card {
    border: 1px solid var(--border-color);
    padding: 20px;
    border-radius: 12px;
  }
  
  .recruiter-lead-team-member-view-btn {
    background-color: var(--maroon-color);
    color: var(--white-color);
    border: none;
    padding: 4px 32px;
    width: 100%;
    border-radius: 12px;
  }
  
  .recruiter-lead-team-member-card-details {
    flex: 1;
  }
  
  .recruiter-lead-team-member-card-details p {
    color: var(--text-semi-color);
    font-size: 16px;
    text-align: justify;
    min-width: 120px;
    /* Ensure enough space for labels */
    white-space: nowrap;
  }
  
  .recruiter-lead-team-member-card-details span {
    color: var(--black-color);
    font-size: 16px;
    font-weight: 700;
    text-align: left;
  }
  
  .recruiter-lead-team-details-card {
    border: 1px solid var(--border-color);
    padding: 24px 16px;
    border-radius: 12px;
  }
  
  .recruiter-lead-team-details-action-button {
    background-color: var(--nav-color);
    border: none;
    color: var(--maroon-color);
    border-radius: 5px;
    padding: 8px 16px;
  }