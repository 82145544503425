.create-job-banner {
    background-color: var(--background-color);
    padding: 10px 0px;
}

.create-job-image {
    width: 251px;
    height: 245px;
}

.create-job-heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.create-job-heading h3 {
    font-size: 32px;
    font-weight: 700;
}

.create-job-heading p {
    font-size: 16px;
    color: var(--text-semi-color);
    text-align: center;
    margin-top: 10px;
}

.create-job-underline {
    border: 0.5px var(--maroon-color) solid;
    width: 200px;

}

.create-job-radio-div {
    border: 1px solid var(--border-color);
    border-radius: 12px;
    padding: 32px 24px;
}

.create-job-radio-div h4 {
    font-size: 22px;
}

.create-job-radio-options {
    border: 1px solid var(--border-color);
    border-radius: 12px;
    padding: 20px 16px;
}

.create-job-radio-options span {
    color: var(--text-semi-color);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.1px;
}

.create-job-radio-options span:focus-visible {
    color: var(--black-color);
}

.create-job-radio-options.selected span {
    color: var(--black-color);
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border-color: var(--maroon-color);
    background-color: var(--maroon-color);
    font-family: "DM Sans";
}

.ant-radio-wrapper,
.create-all-jobs-table.ant-table-wrapper .ant-table-thead>tr>th,
[class^="ant-table"],
[class*=" ant-table"] {
    font-family: "DM Sans" !important;
}

.create-jobs-keywords {
    display: flex;
    gap: 20px;
    align-items: center;
}

.create-jobs-keywords p {
    color: var(--maroon-color);
    border: 1px solid var(--maroon-color);
    padding: 8px 12px;
    border-radius: 5px;
    font-size: 12px;
}

.create-jobs-keywords h4 {
    font-size: 16px;
    text-align: center;
}

.create-jobs-list {
    border: 1px solid var(--border-color);
    border-radius: 12px;
    padding: 20px 16px;
}

.create-all-jobs-table {
    max-height: 300px;
    overflow-y: scroll;

}

::-webkit-scrollbar {
    width: 4px;
}

::-webkit-scrollbar-thumb {
    background-color: var(--maroon-color);
}

::-webkit-scrollbar-track {
    background-color: var(--white-color);
}

.create-all-jobs-table.ant-table-wrapper .ant-table-thead>tr>th {
    background: var(--white-color);
    font-size: 20px;
    font-weight: 700;
}

.continue-btn {
    border: 1px solid var(--maroon-color);
    font-size: 14px;
    font-weight: 500;
    background: var(--maroon-color);
    color: var(--white-color);
    margin-right: 8px;
    padding: 8px 16px;
    border-radius: 8px;
    font-family: "DM Sans";
}

.previous-job-template {
    border: 1px solid var(--maroon-color);
    padding: 16px;
}

.previous-job-template h4 {
    font-size: 20px;
    font-weight: 700;
}

.previous-job-template p {
    font-size: 18px;
    color: var(--text-semi-color);
    margin-bottom: 0;
}

.check-icon {
    color: var(--success-color);
    font-size: 18px;
}

.review-job-details-heading {
    font-size: 24px;
    margin: 20px 0;
}

.privacy-policy-text {
    display: flex;
    gap: 20px;
    align-items: center;
}

.privacy-policy-text p {
    font-size: 16px;
}

.privacy-policy-text span {
    color: var(--maroon-color);
}

.add-job-details-row {
    border: 1px solid var(--border-color);
    border-radius: 12px;
    padding: 32px;
}

.add-job-card {
    border: 1px solid var(--border-color);
    border-radius: 12px;
    padding: 16px;
    height: 500px;
    overflow: hidden;
    transition: height 0.3s ease;
}

.expanded {
    height: auto;
    max-height: 450px;
    overflow-y: scroll;
}

.job-type-buttons {
    border: 1px solid var(--border-color);
    padding: 8px 16px;
    margin-bottom: 10px;
    background-color: var(--white-color);
    border-radius: 12px;
    width: 100%;
    display: flex;
    gap: 10px;
    justify-content: start;
    font-size: 16px;
    align-items: center;
    color: var(--text-semi-color);
}

.show-more-button {
    cursor: pointer;
    color: var(--maroon-color);
    display: flex;
    align-items: center;
    justify-content: end;
}

.add-job-card h4 {
    font-size: 18px;
}

.add-job-card p {
    color: var(--maroon-color);
    text-align: right;
    font-size: 14px;

}

.add-number-people h4 {
    font-size: 18px;
}

.add-job-basic-row {
    border: 1px solid var(--border-color);
    border-radius: 12px;
    padding: 24px 16px;
}

.add-job-basic-row h5 {
    font-size: 16px;
    color: var(--text-semi-color);
}

.add-job-basic-row span {
    color: var(--black-color);
    font-weight: 700;
}

.add-job-basic-row h4 {
    color: var(--black-color);
    font-weight: 700;
    font-size: 16px;
    padding-left: 0;
}

.add-job-basic-input {
    padding: 16px;
    margin-bottom: 5px;
}

.add-job-basic-select {
    height: auto;
    margin-bottom: 5px;
    padding: 0px;
}

.add-job-basic-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 11px;
}

.add-basic-addresses {
    border: 1px solid var(--border-color);
    border-radius: 10px;
    padding: 24px 16px;
    width: fit-content;
}

.add-basic-addresses-content {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 10px;
}

.add-basic-addresses-content p {
    font-size: 16px;
    color: var(--text-semi-color);
    margin-bottom: 0;
}

.add-basic-addresses-content:hover {
    background-color: var(--maroon-color);
    color: var(--white-color);
}

.add-basic-addresses-content:hover {
    color: var(--white-color);
}

.loaction-icon-red {
    color: var(--maroon-color);
    font-size: 16px;
}

.loaction-icon-red:hover {
    color: var(--white-color);
    font-size: 16px;
}

.map-row {
    border: 1px solid var(--border-color);
    border-radius: 12px;
    padding: 10px 0;
    width: 100%;
    height: 250px;
    margin-left: 1px;
}

.ant-switch.ant-switch-checked {
    background: var(--maroon-color);
}

.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
    background: var(--maroon-color);
}

.select-number-people.ant-select-single {
    width: 100%;
}

.select-number-people.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 15px
}

.select-number-people.ant-select .ant-select-selection-placeholder {
    color: var(--maroon-color);
}

.job-settings-heading {
    font-size: 24px;
}

.jobs-setting-section h4 {
    font-size: 18px;
    font-weight: 600;
}

.jobs-setting-section p {
    font-size: 16px;
    color: var(--text-semi-color);
    margin-bottom: 0;
}

.job-settings-row {
    border: 1px solid var(--border-color);
    border-radius: 12px;
    padding: 24px 16px;
}

.file-upload-button {
    background-color: var(--nav-color);
    border: none;
    border-radius: 12px;
}

.add-job-section h3 {
    font-size: 24px;
    font-weight: 700;
    color: var(--black-color);
}

.add-job-section h4 {
    font-size: 22px;
    font-weight: 500;
    color: var(--black-color);
}

.add-job-section h5 {
    font-size: 18px;
    font-weight: 500;
    color: var(--black-color);
}


/* .ant-input {
    padding: 14px;
} */


.add-tag-button {
    width: fit-content;
    padding: 4px 8px;
    background-color: var(--white-color);
    border: 1px solid var(--maroon-color);
    border-radius: 5px;
    color: var(--maroon-color);
}

.add-tag-input {
    border: var(--maroon-color) 1px solid;
    background-color: var(--nav-color);
    color: var(--black-color) !important;
    padding: 6px 12px;
    border-radius: 5px;
    width: fit-content;
    font-size: 14px;
    font-weight: 600;
}


/* .select-job-categories.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 24px
} */


.select-job-categories.ant-select-single {
    width: 100%;
}

/* .select-job-categories.ant-select .ant-select-arrow {
    height: 30px;
} */

.ongoing-jobs-section h2 {
    font-size: 24px;
    font-weight: 600;
}

.ongoing-job-card {
    border: 1px solid var(--border-color);
    padding: 16px;
    border-radius: 12px;
}

.ongoing-job-button {
    background-color: var(--maroon-color);
    border-radius: 4px;
    border: none;
    color: var(--white-color);
    display: flex;
    gap: 10px;
    background-image: url("../../Assets//Image/Recriuiter/on-going-button-bg.png");
    background-position: left;
    background-size: 18px;
    background-repeat: no-repeat;
    padding: 6px 16px;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
}

.ongoing-vertical-line {
    border: 1px solid var(--maroon-color);
}

.ongoing-job-card h3 {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 0;
}

.ongoing-job-card h5 {
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
}

.ongoing-job-card h4 {
    font-size: 14px;
    font-weight: bold;
    color: var(--text-semi-color);
    margin-bottom: 0;
}

.ongoing-job-card p {
    font-size: 14px;
    color: var(--text-semi-color);
}

.ongoing-job-detail-card {
    border: 1px solid var(--border-color);
    padding: 16px 32px;
    border-radius: 5px;
}

.ongoing-job-icons {
    background-color: var(--input-color);
    padding: 22px;
    border-radius: 12px;
    width: fit-content;
}

.ongoing-job-detail-card h5 {
    font-size: 18px;
    color: var(--text-semi-color);
}

.ongoing-job-detail-card h3 {
    font-size: 32px;
}

.custom-ongoing-job-select1 .ant-select-selector::before {
    content: 'Sort : ';
    color: #000;
    margin-right: 8px;
    text-align: center;
    margin-top: 3px;
    font-size: 14px;
    font-weight: 700;

}

.custom-ongoing-job-select2 .ant-select-selector::before {
    content: 'Order By : ';
    color: #000;
    margin-right: 8px;
    text-align: center;
    margin-top: 3px;
    font-size: 14px;
    font-weight: 700;
}

.ongoing-jobs-list {
    border: 1px solid var(--border-color);
    padding: 18px 16px;
    background-color: var(--input-color);
    border-radius: 12px;
    display: flex;
    gap: 40px;
    align-items: center;
}

.ongoing-jobs-list h4 {
    font-size: 16px;
    text-align: center;
    font-weight: 600;
}

.ongoing-jobs-list p {
    font-size: 12px;
    color: var(--text-semi-color);
    margin-bottom: 2px;
}

.ongoing-jobs-list h3 {
    font-size: 16px;
    color: var(--maroon-color);
}

.ongoing-job-status {
    background-color: var(--nav-color);
    padding: 16px 32px;
    border-radius: 12px;
    margin: 0 20px;
}

.ongoing-job-messages {
    border: 1px solid var(--border-color);
    padding: 32px;
    border-radius: 12px;
    max-height: 330px;
    overflow-y: scroll;
}

.messages-badge.ant-badge .ant-badge-count {
    background: var(--background-color);
    color: var(--maroon-color);
}

.ongoing-job-messages h4 {
    font-size: 18px;
    position: relative;
    z-index: 1;
}

.ongoing-job-messages a {
    font-size: 18px;
    color: var(--maroon-color);
    text-decoration: none;
}

.ongoing-job-messages p {
    font-size: 14px;
    color: var(--text-semi-color);
}

.ongoing-job-messages-input.ant-input {
    padding: 8px 16px;
    border-radius: 50px;
    width: 380px;
}

.ongoing-job-messages-input.ant-input-outlined {
    background: var(--background-color) !important;

}

.send-icon {
    background-color: var(--background-color);
    padding: 8px;
    border-radius: 50%;
    color: var(--maroon-color);
}


.ongoing-job-detail-card h3 {
    font-size: 32px;
}
.review-job-tags{
    padding: 8px 16px;
    background-color: var(--white-color);
    border: 1px solid var(--border-color);
}
.filter-tabs{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    position: sticky;
    top: 5px;
    z-index: 10;
    background-color: var(--white-color);
}
/* .filter-modal{
    height: 85vh;
    overflow-y: auto;
} */
.filter-form{
    position: relative;
    z-index: 1;
    height: 300px;
    overflow-y: auto;
}
.filter-slider.ant-slider .ant-slider-track{
    background-color: var(--maroon-color);
}
.ant-slider .ant-slider-handle::after{
    box-shadow: 0 0 0 2px var(--maroon-color);
}
.view-job-info-card{
    border: 1px solid var(--border-color);
    padding: 16px;
    border-radius: 12px;
}
.view-job-section-heading{
    font-size: 24px;
}
.view-job-info-card h2{
    font-size: 22px;
}
.view-job-info-card p{
    font-size: 14px;
    color: var(--text-semi-color);
    margin-bottom: 2px;
}
.view-job-star-icon{
    color: var(--warning-color);
}
.view-job-info-card span{
    font-weight: 700;
    color: var(--black-color);
    font-size: 14px;
}
.view-job-icon-red{
    color: var(--maroon-color);
    font-size: 20px;
}
.view-job-info-card ul li{
    color: var(--text-semi-color);
    font-size: 14px;
}
.view-job-info-card h3{
    font-size: 16px;
    font-weight: 700;
}