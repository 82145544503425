.recruiter-task-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}

.recruiter-task-title {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.1px;
    color: var(--black-color);
}

.recruiter-button-group {
    display: flex;
    gap: 0.5rem;
}

.recruiter-task-btn {
    background-color: var(--maroon-color);
    color: var(--white-color);
    padding: 6px 12px;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    border: none;
    cursor: pointer;
    font-size: 14px;
}

.recruiter-task-btn:hover {
    background-color: var(--background-color);
    color: var(--maroon-color);
}

.recruiter-btn-icon {
    margin-right: 0.5rem;
}

.recruiter-tab-group {
    display: flex;
    margin-bottom: 1.5rem;
}

.recruiter-tab {
    padding-bottom: 0.5rem;
    margin-right: 1rem;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    background: var(--white-color);
}

.recruiter-tab.active {
    color: var(--maroon-color);
    border-bottom-color: var(--maroon-color);
    ;
    font-weight: 600;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    background: var(--white-color);
}

.recruiter-task-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1rem;
}

.recruiter-assigned-task-card,
.recruiter-ongoing-task-card,
.recruiter-completed-task-card {
    background-color: var(--white-color);
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.01);
    padding: 1rem;
    border: 1px solid var(--border-color);
}

.recruiter-assigned-task-card {
    border-top: 5px solid var(--maroon-color);
}

.recruiter-ongoing-task-card {
    border-top: 5px solid var(--warning-color);
}

.recruiter-completed-task-card {
    border-top: 5px solid var(--success-color);
}

.recruiter-task-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.recruiter-task-id {
    color: var(--text-semi-color);
}

.recruiter-task-detail {
    margin-bottom: 0.5rem;
}

.recruiter-task-label {
    font-weight: 600;
    margin-right: 0.5rem;
}

.recruiter-project-name {
    display: flex;
    align-items: center;
}

.recruiter-star-icon {
    color: var(--warning-color);
    margin-left: 0.5rem;
}

.user-icon,
.client-icon {
    color: var(--maroon-color);
    margin-right: 0.25rem;
}

.recruiter-not-assigned {
    background-color: var(--maroon-color);
    color: var(--white-color);
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    font-size: 0.875rem;
}

.recruiter-status-ongoing {
    background-color: var(--warning-color);
    color: var(--white-color);
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    font-size: 0.875rem;
}

.recruiter-status-completed {
    background-color: var(--success-color);
    color: var(--white-color);
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    font-size: 0.875rem;
}

.recruiter-status-default {
    color: var(--white-color);
    background-color: var(--maroon-color);
    border: 1px solid var(--maroon-color);
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    font-size: 0.875rem;
}

@media (max-width: 768px) {
    .recruiter-task-grid {
        grid-template-columns: 1fr;
    }
}

.recruiter-horizontal-action-dropdown {
    background-color: var(--input-color);
    display: flex;
    align-items: center;
    color: var(--maroon-color);
}