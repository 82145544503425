.main-title-all {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.main-title-all span {
  font-size: 28px;
  font-weight: 700;
  color: #1e1e1e;
  letter-spacing: 0.3px;
}

.main-title-all button {
  border: 1px solid #ce1b28;
  padding: 8px 16px;
  border-radius: 6px;
  color: #ce1b28;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.1px;
  background: transparent;
}

.main-title-all button:hover {
  color: #fff;
  background: #ce1b28;
}

.filter-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.select-options {
  display: flex;
  align-items: center;
}

.custom-date-picker input {
  font-size: 16px !important;
  padding: 2px 12px !important;
  font-weight: 700 !important;
  font-family: "Poppins" !important;
}

.custom-date-picker .ant-picker-clear {
  color: #878790 !important;
}

.custom-date-picker .ant-picker-clear:hover {
  color: #666 !important;
}

.delete-button-table {
  font-size: 16px;
  font-weight: 700;
  background: rgba(255, 0, 0, 0.1);
  padding: 8px;
  border-radius: 50%;
  color: #ff0000;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.edit-button-table {
  font-size: 16px;
  font-weight: 700;
  background: rgba(0, 125, 252, 0.1);
  padding: 8px;
  border-radius: 50%;
  color: #007dfc;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.edit-button-table:hover,
.delete-button-table:hover {
  transform: scale(1.1);
}

.edit-button {
  border: 0;
  padding: 8px 12px;
  color: #fff;
  background: #ce1b28;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
  letter-spacing: 0;
}

.filter-button {
  border: 0;
  padding: 8px 24px;
  color: #fff;
  background: #ce1b28;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
  letter-spacing: 0;
  position: fixed;
  bottom: 10px;
}

.filter-panel {
  position: fixed;
  top: 0;
  right: -300px;
  width: 300px;
  height: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  z-index: 9999;
  padding: 24px;
}

.filter-panel.show {
  transform: translateX(-300px);
}

.filter-panel p {
  padding: 20px;
  font-size: 16px;
}

.filter-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter-title span {
  color: #ce1b28;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: normal;
}

.close-icon {
  background: rgba(255, 0, 0, 0.1);
  color: #ce1b28;
  font-size: 16px;
  font-weight: 600;
  padding: 8px;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.close-icon:hover,
.save-button-post:hover {
  transform: scale(1.1);
}

.save-button-post {
  background: #ce1b28;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  padding: 6px 12px;
  border-radius: 8px;
  transition: transform 0.3s ease;
  border: 0;
}

.add-post-timeline {
  background: #f4f4f4;
  padding: 36px;
  text-align: center;
  border: #e5e5e5;
  margin-bottom: 24px;
}

.title-timeline-add-post h3 {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: normal;
  color: #000;
}

.timeline-tracker {
  display: flex;
  align-items: center;
  justify-content: center;
}

.next-button button {
  border: 0;
  background: #ce1b28;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 8px 12px;
  width: 100%;
  border-radius: 6px;
}

.next-button>.cancel-button{
  width: 100px !important;
  margin-left: 10px;
  border: 0;
  border: 1px solid #ce1b28;
  background:#fff ;
  color: #ce1b28;
  font-size: 16px;
  font-weight: 500;
  padding: 8px 12px;
  width: 100%;
  border-radius: 6px;
}
.next-button>.cancel-button:hover{
  background-color: #ce1b28;
  color: #fff;
}

.previous-button button {
  margin-bottom: 10px;
  border: 1px solid #ce1b28;
  background: #fff;
  color: #ce1b28;
  font-size: 16px;
  font-weight: 500;
  padding: 8px 12px;
  width: 100%;
  border-radius: 6px;
}

.disabled {
  cursor: not-allowed;
}

.add-category button{
  border: 0;
  background: #fff;
  color: #ce1b28;
  border: 1px solid #ce1b28;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 12px;
  border-radius: 6px;
}

.description-layout{
  height: 25.35rem;
  border-radius: 5px;
  border: 1px solid rgb(204,204,204);
}

.ql-container.ql-snow{
  border: 0 !important;
}

.description-title span{
   font-size: 14px;
   padding: 12px;
   color: #323941;
}


.drop-down{
  border:1px solid #ce1b28;
}

.drop-down-panel{
  font-size: 16px;
  font-weight: 600;
}
.drop-down-info{
  font-size: 14px;
  font-weight: 400;
}

.dropdown-content-accordion {
  display: none;
}

.dropdown-accordion.clicked .dropdown-content-accordion {
  display: block;
}



.ant-collapse>.ant-collapse-item >.ant-collapse-header {
  align-items: center !important;
}

.table-headers{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.table-headers>div{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-headers>div>button{
  border: 1px solid #ce1b28;
  padding: 4px 16px;
  border-radius: 6px;
  color: #ce1b28;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.1px;
  background: transparent;
}

.table-headers>div>button:hover{
  color: #fff;
  background: #ce1b28;
}

.keywords-seo{
  flex-wrap: wrap;
  justify-content: space-between;
}

.keyword-loader{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba( 0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  color: #ce1b28;
}

.post-url-input{
  padding: 10px 0;
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 0;
}
.post-url-input>h5{
  margin: 0;
  /* margin-top: 10px; */
}
.post-url-input>input{
  margin-left: 5px;
  color: #878790;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  width: 100%;
  height: 50px;
  padding: 0 8px;
}