.recruiter-applied-applicants-table.ant-table-wrapper .ant-table-thead>tr>th {
    background: var(--white-color);
    color: var(--black-color);
}

.recruiter-applied-applicants-table.ant-table-wrapper .ant-table {
    color: var(--text-semi-color);
}

.recruiter-applicant-status-tag {
    background-color: var(--maroon-color);
    border-radius: 12px;
    color: var(--white-color);
    padding: 2px 24px;
}

.recruiter-applicants-page-overview h2 {
    font-size: 24px;
    font-weight: 800;
    letter-spacing: -0.1px;
    color: var(--black-color);
}

.recruiter-reminder-btn{
    border: 1px solid var(--maroon-color);
    font-size: 14px;
    font-weight: 500;
    background: var(--maroon-color);
    color: var(--white-color);
    padding: 8px 16px;
    border-radius: 8px;
    font-family: "DM Sans";
}
.recruiter-tab-group {
    display: flex;
    margin-bottom: 1.5rem;
}