.recruiter-dashboard-btn {
    background: var(--maroon-color);
    color: var(--white-color);
    border: 0;
    border-radius: 24px;
    padding: 8px 24px
}

.recruiter-dashboard-card {
    background-color: var(--background-color);
    border-radius: 12px;
    padding: 12px;
    border: 1px solid var(--border-color);
    font-family: "DM Sans";
}

.card-subtitle {
    color: var(--text-semi-color);
}

.card-title {
    font-weight: 800;
}

.recruiter-dashboard-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.recruiter-dashboard-title h2 {
    color: var(--text-color);
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.1px;
    margin-bottom: 0;
}

.recruiter-dashboard-total-overview {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pie-chart-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.progress-percentage {
    position: absolute;
    top: 25px;
    font-size: 12px;
    color: var(--text-semi-color); 
}
.progress-bar-wrapper{
    margin-top: 25px;
    position: relative;
   left: -25px;
    display: flex;
    gap: 10px;
    align-items: center;
}
.recruiter-dashboard-card {
    background-color: var(--background-color);
    border-radius: 12px;
    padding: 12px;
    border: 1px solid var(--border-color);
    font-family: "DM Sans";
}
.recruiter-dashboard-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.recruiter-dashboard-title h2 {
    color: var(--text-color);
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.1px;
    margin-bottom: 0;
}

.recruiter-dashboard-total-overview {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.recruiter-dashboard-filter{
    background-color: var(--nav-color);
    color: var(--maroon-color);
    border: none;
    border-radius: 20px;
    padding: 8px 16px;
}
.progress-bar-background {
    background-color: var(--border-color);
    border-radius: 5px;
    width: 100%;
    height: 8px;
    overflow: hidden;
  }
  
  .progress-bar-fill {
    height: 100%;
    border-radius: 5px;
  }
  .recruiter-card-hiring-icon.ant-avatar{
    background-color: var(--input-color);
    color: var(--maroon-color);
  }
  .recruiter-card-todo-list h3{
    font-size: 16px;
    color: var(--black-color);
    margin-bottom: 0;
  }
  .recruiter-card-todo-list span{
    font-size: 9px;
    color: var(--text-semi-color);
  }
  .recruiter-card-todo-list p{
    font-size: 16px;
    color: var(--text-semi-color);
    margin-bottom: 0;
  }
  .recruiter-horizontal-line{
    border: 1px solid var(--border-color);
  }
  .recruiter-dashboard-title a{
    color: var(--text-semi-color);
  }
  .recruiter-team-notification{
    background-color: var(--white-color);
    border-radius: 12px;
    /* border: 1px solid var(--border-color); */
    padding: 14px 16px;
  }
  .recruiter-team-notification h2{
    font-size: 16px;
    color: var(--black-color);
  }
  .recruiter-team-notification span{
    font-size: 12px;
    color: var(--text-semi-color);
  }
  .recruiter-notification-send{
    background-color: transparent;
    border: none;
    color: var(--maroon-color);
    font-size: 18px;

  }