.recruiter-settings-section h2 {
    font-size: 22px;
    font-weight: 700;
  }
  
  .recruiter-settings-options {
    border: 1px solid var(--border-color);
    padding: 32px;
    border-radius: 12px;
    position: sticky;
    top: 100px;
  }
  
  .recruiter-settings-items {
    font-size: 16px;
    color: var(--text-semi-color);
    padding: 10px;
  }
  
  .recruiter-settings-items:hover,
  .recruiter-settings-items.active {
    background-color: var(--maroon-color);
    color: var(--white-color);
    border-radius: 10px;
    cursor: pointer;
  }
  
  .recruiter-profile-icon-section {
    text-align: center;
    display: flex;
    align-items: center;
  }
  
  .recruiter-profile-image {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    background-color: var(--background-color);
  }
  
  .recruiter-edit-icon-button {
    border: none;
    color: var(--black-color);
    cursor: pointer;
    background: var(--white-color);
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: relative;
    left: -25px;
    top: 30px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
      rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  }
  
  .recruiter-edit-icon-button:hover {
    color: var(--maroon-color);
    background: var(--background-color);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
      rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  }
  
  .recruiter-personal-information-section h4 {
    color: var(--maroon-color);
    font-size: 16px;
    font-weight: 700;
    font-family: "DM Sans";
  }
  
  .recruiter-personal-information-section span {
    font-size: 12px;
    color: var(--text-semi-color);
    font-family: "DM Sans";
  }
  
  .recruiter-personal-information-section h3 {
    font-size: 16px;
    font-weight: 700;
    font-family: "DM Sans";
  }