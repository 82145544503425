.ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected {
  color: #ce1b28;
}
.ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected::after {
  border-bottom-color: #ce1b28;
}
.Ticket-overview {
  border: 1px solid #ccc;
  margin-top: 18px;
  padding: 12px;
  background-color: #fefefe;
  border-radius: 12px;
}
.Ticket-overview h2 {
  font-size: 16px;
  font-weight: 600;
}
.Ticket-overview h3 {
  font-size: 14px;
  font-weight: 600;
}

.Ticket-overview p {
  font-size: 14px;
  font-weight: 400;
}

.Ticket-overview span {
    font-size: 14px;
    font-weight: 400;
  }

.Ticket-overview a {
  text-decoration: underline;
  color: #ce1b28;
  font-size: 14px;
  font-weight: 500;
}
