.faq-collapse {
  width: 100%;
}

.faq-panel {
  margin-bottom: 16px;
}

.faq-collapse .ant-collapse-borderless {
  background-color: var(--white-color);
}

.faq-question {
  color: var(--text-semi-color);
  font-size: 16px;
  font-weight: 600;
  font-family: "DM Sans";
}

.faq-question.expanded {
  color: var(--maroon-color);
}

.faq-number {
  margin-right: 10px;
  color: var(--text-semi-color);
  font-size: 16px;
  font-weight: 600;
  font-family: "DM Sans";
}

.faq-number.expanded {
  color: var(--maroon-color);
}

.faq-collapse .ant-collapse .ant-collapse-content {
  color: var(--text-semi-color);
  font-size: 16px;
  font-family: "Dm Sans";
  font-weight: 400;
  letter-spacing: -0.1px;
}

.faq-section h3 {
  font-size: 18px;
  font-weight: 600;
  color: var(--black-color);
  letter-spacing: -0.1px;
}

.tabs {
  font: 24px;
  font-weight: 700;
  padding-bottom: 0.5rem;
  margin-right: 1rem;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  background: var(--white-color);
}

.tabs.active {
  color: var(--maroon-color);
  border-bottom-color: var(--maroon-color);
  font-weight: 600;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  background: var(--white-color);
}

.faq-plus-icon {
  background-color: var(--nav-color);
  color: var(--maroon-color);
  border-radius: 50%;
}

.help-chat-box-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  padding: 10px;
  background-color: var(--white-color);
  height: 70vh;
}

.help-messages-container {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.helpMessage {
  margin: 5px 0;
  padding: 10px;
  border-radius: 10px;
  max-width: 80%;
  word-break: break-word;
}

.helpMessage.user {
  background-color: var(--nav-color);
  align-self: flex-end;
  text-align: right;
  margin-left: auto;
  padding: 10px;
  font-size: 14px;
  border-top-left-radius: 24px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
}

.helpMessage.bot {
  background-color: var(--background-color);
  align-self: flex-start;
  text-align: left;
  margin-right: auto;
  font-size: 14px;
  border-top-left-radius: 0;
  border-top-right-radius: 24px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
}

.help-input-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid var(--border-color);
  border-radius: 12px;
  padding: 5px;
}

.help-message-input {
  flex: 1;
  padding: 10px;
  border: none;
  outline: none;
  border-radius: 12px;
}

.help-send-button {
  background-color: transparent;
  border: none;
  color: var(--maroon-color);
  font-size: 24px;
  cursor: pointer;
}

.help-edit-button {
  background-color: transparent;
  border: none;
  color: var(--maroon-color);
  font-size: 16px;
  cursor: pointer;
  position: relative;
  left: -40px;
}

.ticket-tab-group {
  display: flex;
  gap: 10px;
}

.ticket-tabs {
  padding-bottom: 0.5rem;
  margin-right: 1rem;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  font-size: 16px;
  color: var(--text-semi-color);
  border-top: 0;
  border-left: 0;
  border-right: 0;
  background: var(--white-color);
}

.ticket-tabs.active {
  color: var(--maroon-color);
  border-bottom-color: var(--maroon-color);
  ;
  font-weight: 600;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  background: var(--white-color);
}

.all-tickets-card {
  border: 1px solid var(--border-color);
  border-radius: 12px;
  padding: 24px 16px;
  height: 270px;
  position: relative;
}

.all-tickets-card h4 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}

.raise-ticket-vertical-line {
  border: 1px solid var(--maroon-color);
  height: 16px;
}
.all-tickets-card p{
    color: var(--text-semi-color);
    font-size: 12px;
}
.raise-ticket-horizontal-line{
    border: 1px solid var(--border-color);
    margin-block: 10px;
}
.view-ticket-btn {
    border: 1px solid var(--maroon-color);
    font-size: 14px;
    font-weight: 500;
    background: var(--maroon-color);
    color: var(--white-color);
    margin-right: 8px;
    padding: 4px 8px;
    border-radius: 8px;
    font-family: "DM Sans";
    bottom: 10px;
    position: absolute;
}
.view-ticket-btn:hover {
    background: var(--white-color);
    color: var(--maroon-color);
}
.view-ticket-card h4{
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
}
.view-ticket-card p{
    color: var(--text-semi-color);
    font-size: 16px;
}
.view-tickets-heading h3{
    font-size:32px ;
}
.read-more-button{
  background-color: transparent;
  border: none;
  color: var(--maroon-color);
  font-size: 18px;
}
.replied-tickets{
  background-color: var(--background-color);
  padding: 10px;
  position: relative; 
  border-radius: 0px 15px 15px 15px;
}
.replied-tickets::before {
  content: "";
  position: absolute;
  top: 0px; 
  left: -10px; 
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid var(--background-color); 
}

.replied-tickets h6{
  font-size: 14px;
  font-weight: bold;
}
.replied-tickets p{
  margin-bottom: 0;
  font-size: 14px;
}
.replied-tickets span{
  font-size: 12px;
display: flex;
justify-content: end;
}
.ticket-delete-button{
  background-color: var(--input-color);
  border: none;
  color:var(--maroon-color);  
  display: flex;
  align-items: center;
  border-radius: 5px;
}
.popconfirm-yes-button {
  background-color: var(--maroon-color);
  border: none;
  color:var(--white-color) !important;
}


.popconfirm-no-button {
  background-color: var(--white-color);
  border-color: var(--maroon-color);
  color: var(--maroon-color) !important;
}
.no-ticket-found img{
  width: 200px;
  height:200px;

}
.no-ticket-found h4{
  font-size: 24px;
  margin-top: 12px;
  /* font-weight: 600; */
}