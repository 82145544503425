.navbar-header {
    background: var(--background-color);
    padding: 12px 24px;
    margin-left: 240px;
    border-bottom: 1px solid var(--border-color);
    position: sticky;
    top: 0;
    z-index: 999;
    display: flex;
    align-items: center;
}

.search-container {
    position: relative;
}

.search-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    display: flex;
    align-items: center;
    padding-left: 8px;
    font-size: 24px;
    color: var(--text-color);
}

.search-icon svg {
    color: var(--text-color);
}

.search-input {
    border: 1px solid var(--border-color);
    padding: 8px 32px;
    background: transparent;
    border-radius: 8px;
    color: var(--text-semi-color);
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.5px;
}

.search-input:focus {
    outline: none;
}

.toggle-icons {
    font-size: 14px;
    color: var(--maroon-color);
}

.notification-button {
    position: relative;
    display: flex;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
    color: var(--text-color);
}

.notification-badge {
    position: absolute;
    top: -10px;
    right: 0;
    background-color: var(--maroon-color);
    color: var(--white-color);
    border-radius: 50%;
    padding: 3px 6px;
    font-size: 10px;
}

.user-info {
    padding: 0;
}

.user-info span {
    color: var(--border-color);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.3px;
}

.user-image {
    border: 0;
    background: transparent;
    border-radius: 50%;
    width: 48px;
    height: 48px;
}

.dropdown-menu {
    position: absolute;
    top: calc(85%);
    right: 15px;
    background: var(--maroon-color);
    border-radius: 4px;
    z-index: 999;
    display: block;
    visibility: visible;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    border: 0;
}

.dropdown-menu::before {
    content: "";
    position: absolute;
    top: -8px;
    right: 65%;
    border-width: 0 8px 8px 8px;
    border-style: solid;
    border-color: transparent transparent var(--maroon-color) transparent;
}


.dropdown-menu-items {
    padding: 0 8px;
}

.dropdown-item {
    display: block;
    padding: 8px 16px;
    text-decoration: none;
    color: var(--white-color);
    border-radius: 2px;
}

.profile--icon {
    border-radius: 50%;
    width: 40px;
    height: 40px;
}

.toggle-container {
    position: relative;
}

.toggle-input {
    display: none;
}

.toggle-label {
    display: inline-block;
    width: 50px;
    height: 24px;
    background-color: var(--border-color);
    border-radius: 12px;
    position: relative;
    cursor: pointer;
}

.toggle-button {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 20px;
    height: 20px;
    background-color: var(--white-color);
    border-radius: 50%;
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.toggle-input:checked+.toggle-label {
    background-color: var(--maroon-color);
}

.toggle-input:checked+.toggle-label .toggle-button {
    left: 28px;
}










/* ================================================================================================== */

.line-dashed {
    border-bottom: 2px dashed var(--maroon-color);
    margin: 8px 0 16px 0;
}

.documentation-section {
    border-radius: 12px;
    padding: 12px;
    /* background-image: url(../../Assets/bg/sidebar-bg.png); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.icon-documentation {
    background: var(--white-color);
    padding: 0px;
    border-radius: 12px;
    color: var(--maroon-color);
    font-size: 24px;
    font-weight: 600;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content-documentation span {
    color: var(--white-color);
    font-size: 14px;
    font-weight: 600;
    margin-top: 12px;
}

.btn-documentation {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    color: var(--text-color);
    background: var(--white-color);
    border-radius: 12px;
    text-align: center;
    padding: 8px 12px;
    margin-top: 12px;
}

.sidebar {
    width: 240px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    background-color: var(--background-color);
    transition: left 0.3s ease;
    z-index: 1000;
    border-right: 1px solid var(--border-color);
}

.sidebar.open {
    left: 0;
}

.toggle-btn {
    position: absolute;
    top: 20px;
    left: 20px;
}

.sidebar-content {
    padding: 8px 12px 24px 12px;
}

.sidebar-header {
    text-align: center;
}

.sidebar-header img {
    width: 160px;
    padding: 0 0 20px 0;
    margin-left: 10px;
    margin-top: 35px;
}

.sidebar-nav {
    overflow-y: auto;
    max-height: calc(100vh - 60px);
}

.sidebar-nav::-webkit-scrollbar {
    width: 4px;
}

.sidebar-nav::-webkit-scrollbar-thumb {
    background-color: transparent;
}

.sidebar-nav::-webkit-scrollbar-track {
    background-color: transparent;
}

.sidebar-nav ul {
    list-style-type: none;
    padding: 0;
}

.sidebar-nav ul li {
    margin-bottom: 10px;
}

.sidebar-nav ul li a,
.sidebar-nav ul li div {
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0;
    color: var(--text-semi-color);
    border-radius: 24px;
    text-decoration: none;
    padding: 8px 16px;
    width: 100%;
    background-color: transparent;
    cursor: pointer;
    user-select: none;
}

.sidebar-nav ul li a:focus,
.sidebar-nav ul li div:focus {
    color: var(--maroon-color) !important;
    background: var(--nav-color) !important;
}

.sidebar-nav ul li a:hover,
.sidebar-nav ul li div:hover {
    color: var(--maroon-color);
    background: var(--nav-color);
}


.sidebar-nav ul li a svg,
.sidebar-nav ul li div svg {
    margin-right: 10px;
}

.sidebar-footer {
    position: absolute;
    bottom: 20px;
    left: 20px;
}

.sidebar-footer a {
    font-size: 15px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0;
    color: var(--input-color);
    border-radius: 24px;
    text-decoration: none;
}

.active-nav-links {
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0;
    background: var(--maroon-color);
}

/* .sidebar-nav ul li a:hover .sidebar-icon,
.sidebar-nav ul li div:hover .sidebar-icon {
    color: var(--maroon-color) !important;
} */

.sub-menu {
    padding-left: 30px;
    display: none;
}

.sub-menu.active {
    display: block;
}

.sub-nav-list {
    margin-bottom: 0 !important;
    margin-top: 4px !important;
    margin-left: 20px !important;
    margin-right: 20px !important;
}

.sub-nav-link {
    padding: 8px 20px;
    text-decoration: none;
    display: block;
    color: var(--maroon-color) !important;
    background-color: var(--nav-color) !important;
    border-radius: 8px !important;
}

.sidebar-nav ul li a.active-nav-links,
.sidebar-nav ul li div.active-nav-links,
.sub-menu li .sub-nav-link .active-nav-links {
    background-color: var(--maroon-color);
    color: var(--white-color);
}

.sub-nav-link:hover {
    padding: 8px 16px;
    text-decoration: none;
    display: block;
    color: var(--white-color) !important;
    background-color: var(--maroon-color) !important;
    border-radius: 8px !important;
}

.sub-nav-link:hover {
    background-color: var(--maroon-color);
}

.main-wrapper {
    padding: 16px;
    margin-left: 240px;
    background: var(--white-color);
    min-height: 80vh;
    height: 100%;
}
.custom-swal-image{
    width: 300px;
}
.collapse-btn {
    position: absolute;
    top: 10px;
    right: 25px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    margin-bottom: 20px;
  }
  .sidebar-nav.collapsed{
    margin-top: 30px;
  }
  .sidebar.collapsed {
    width: 80px; 
  }
  .main-wrapper.collapsed{
    margin-left: 80px;
  }
  .sidebar-content.collapsed{
    padding: 50px 12px 24px 12px;

  }
  .navbar-header.collapsed{
    margin-left: 80px;
  }
  
@media (max-width: 768px) {
    .toggle-btn {
      position: fixed;
      top: 20px;
      left: 10px;
      border: none;
      color: #1b3a57;
      z-index: 1000;
      display: block;
    }
    .close-btn {
      position: absolute;
      top: 10px;
      right: 10px;
      background: none;
      border: none;
      font-size: 24px;
      cursor: pointer;
    }
    .sidebar {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
    }
  
    .sidebar.open {
        display: block;
        transform: translateX(0);
      }
    
    .main-wrapper {
      padding: 10px;
      margin-left: 0px !important;
    }
    .mobile-search{
      margin-left: 0 !important;
    }
    .navbar-header{
      margin-left: 0px !important;
    }
    .header-search-bar{
      display: none;
    }
    .sidebar-header img {
        padding: 0 0 20px 20px;
        margin-left: 20px;

    }
  }