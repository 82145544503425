.notification-switch-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.notification-switch {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.notification-switch>span {
  font-size: 15px;
  font-weight: 700;
}

.notification-layout {
  height: fit-content;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.ant-menu-light {
  border-radius: 8px;
}

.overview-list>li {
  padding: 20px;
  font-size: 14px;
  color: orangered;
  border-bottom: 1px solid #ccc;
}

.overview-list-title {
  color: #000;
  font-size: 16px;
  font-weight: 500;
}

.overview-list-content {
  color: #1e1e1e;
}

.content-bar {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
}

.content-bar p {
  margin: 0;
}

.ant-menu-title-content {
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins";
}

.icon-data-fill {
  background: orangered;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
  width: 28px;
  height: 28px;
}

.no-notifications{
  border-top: 1px solid #ce1b28;
  padding: 12px 0 0 0;
}